@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Times New Roman", Times, serif;
  background-color: white !important;
}

.scrollbarHide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbarHide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.artileNameBtn {
  border: 1px solid #bd9229;
  color: #bd9229;
}

.artileNameBtn:hover {
  background-color: #bd9229;
  color: white;
}

.secondaryBtnTop {
  border: 1px solid white;
}

.secondaryBtnTopInner:hover .secondaryBtnTop {
  border: 1px solid #bd9229 !important;
}

.secondaryBtnTopInner {
  background-color: #fff;
  position: absolute;
  top: 5px;
  left: 5px;
}

.secondaryBtnTopInner:hover {
  top: 0;
  left: 0;
  background-color: #bd9229;
}

.sellingCard-hover-div {
  visibility: hidden;
  width: 100%;
  height: 0;
  color: #bd9229;
  letter-spacing: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: height 0.5s;
  background: rgba(240, 240, 240, 0.856) !important;
}

.sellingCard:hover .sellingCard-hover-div {
  visibility: visible;
  height: 15%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px;
}

.bannerImg {
  position: absolute;
  top: 48px;
}

@media only screen and (max-width: 768px) {
  .bannerImg {
    position: absolute;
    top: 0px;
  }
}

.borderNavlinks:hover {
  border-bottom: 1px solid #bd9229;
}

.sublinksHover:hover {
  color: #bd9229 !important;
}

.modal-content {
  border-radius: 0 !important;
  outline: none;
  border: none;
}